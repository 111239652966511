import type { Params } from '@angular/router';

import { createAction, props } from '@ngrx/store';

const basename = '[Router]';

export const RouterActions = {
  navigateToProjects: createAction(
    `${basename} navigate to projects`,
    props<{
      lang?: string;
      optional?: boolean;
      queryParams?: Params;
    }>(),
  ),
};
